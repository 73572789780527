/** @jsx jsx */
import React from "react"; //eslint-disable-line no-unused-vars
import { Box, Container, Grid, Flex, Heading, Image, jsx } from "theme-ui";
import { graphql } from "gatsby";

import parse from "html-react-parser";

import ArrowLink from "../components/ArrowLink";
import LinkButton from "../components/LinkButton";
import BlogCard from "../components/BlogCard";
import Footer from "../components/Footer";
import FramedImage from "../components/FramedImage";
import Header from "../components/Header";
import MarkdownRenderer from "../components/MarkdownRenderer";

import PageData from "../../content/pages/home.yml";
import SEO from "../components/SEO";

export const query = graphql`
  query {
    heroDots: file(relativePath: { eq: "hero-dots.svg" }) {
      id
      publicURL
      extension
    }
    target: file(relativePath: { eq: "target.svg" }) {
      id
      publicURL
      extension
    }
    barGraph: file(relativePath: { eq: "bar-graph.svg" }) {
      id
      publicURL
      extension
    }
    clipboard: file(relativePath: { eq: "clipboard.svg" }) {
      id
      publicURL
      extension
    }
    percentSign: file(relativePath: { eq: "percentage-sign.svg" }) {
      id
      publicURL
      extension
    }
    zeroDollars: file(relativePath: { eq: "zero-dollars.svg" }) {
      id
      publicURL
      extension
    }
    dosingGif: file(relativePath: { eq: "dosing-animation.gif" }) {
      id
      publicURL
      extension
    }
    ourClientsGif: file(relativePath: { eq: "our-clients-gif.gif" }) {
      id
      publicURL
      extension
    }
    strategicAnemiaAdvisor: file(
      relativePath: { eq: "strategic-anemia-advisor.gif" }
    ) {
      id
      publicURL
      extension
    }

    allFeedDosisNews (
      sort: {
        fields: [isoDate],
        order: [DESC]
      },
      limit: 3
    ) {
      edges {
        node {
          contentSnippet
          id
          isoDate(formatString: "MM/DD/YYYY")
          link
          title
        }
      }
    }
  }
`;

const parseImage = html => {
  const data = parse(html);
  let image = data.find(x => x.type === "img");
  if (image) {
    return image.props.src;
  }
  return false;
};

const Index = ({ data }) => {
  let blogArray = [];

  const feedDosisNews = data.allFeedDosisNews.edges;

  for (let i = 0; i < feedDosisNews.length; i++) {
    const blog = feedDosisNews[i];

    let { isoDate, contentSnippet, link, title } = blog.node;

    const encoded =
      typeof blog.node.content !== "undefined" && blog.node.content.encoded;

    let image = null;
    let imageFound = "cover";

    if (encoded !== false) {
      // Parse content encoded for image; if none, fall back to feedMeta image,
      // if it exists, if feedMeta image doesn't exist, just don't load an image

      imageFound = true;
      image = parseImage(encoded);
    }

    if (contentSnippet.split(" ").length > 15) {
      contentSnippet = contentSnippet.split(" ");

      const remainingSnippet = contentSnippet.length - 15;
      contentSnippet.splice(-remainingSnippet);

      contentSnippet = contentSnippet.join(" ");
    }

    if (contentSnippet.includes("[…]")) {
      contentSnippet = contentSnippet.replace("[…]", "...");
    } else {
      contentSnippet += " ...";
    }

    blogArray.push({
      blogImageFound: imageFound,
      category: "Dosis News",
      date: isoDate,
      excerpt: contentSnippet,
      image: image,
      link: link,
      title: title,
    });
  }

  // 2022-11-01 @atom-c => query sorted, limited results instead
  // blogArray = blogArray.sort((a, b) => new Date(b.date) - new Date(a.date));
  // blogArray = blogArray.slice(0, 3);

  return (
    <>
      <SEO />

      <Header
        heroImage={data.heroDots.publicURL}
        heroImageAlt="spiral image dots"
        heroText={PageData.intro}
        heroTitle={PageData.title}
        small={PageData.mobile_font_sizes.hero_title}
        large={PageData.desktop_font_sizes.hero_title}
      />

      <Box as="section" py={[15, null, 17]}>
        <Container>
          <Box sx={{ width: "100%", mx: "auto", textAlign: "center" }}>
            <Heading
              sx={{
                fontSize: [
                  PageData.mobile_font_sizes.title,
                  null,
                  null,
                  PageData.desktop_font_sizes.title,
                ],
              }}
              mb={9}
            >
              {PageData.block_1_title}
            </Heading>

            <MarkdownRenderer
              sx={{
                textAlign: PageData?.block_1_alignment
                  ? PageData?.block_1_alignment
                  : "center",
              }}
              small={PageData.mobile_font_sizes.copy}
              large={PageData.desktop_font_sizes.copy}
            >
              {PageData.block_1_intro}
            </MarkdownRenderer>
          </Box>

          <Box my={(9, null, null, 15)}>
            <Image
              src={PageData.block_1_image}
              sx={{ maxWidth: "100%", width: "100%" }}
            />
          </Box>

          <Flex sx={{ flexWrap: "wrap", justifyContent: "space-between" }}>
            {PageData.block_1_columns.map((column, index) => (
              <Box
                sx={{
                  flex: ["0 0 100%", null, "0 0 45%"],
                  mb: [14, null, null, 0],
                }}
                key={index}
              >
                <Heading
                  as="h2"
                  mb={9}
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: [
                      PageData.mobile_font_sizes.sub_title,
                      null,
                      null,
                      26,
                    ],
                  }}
                  variant="textLG"
                >
                  {column.title}
                </Heading>

                <MarkdownRenderer
                  sx={{
                    textAlign: column.content_alignment
                      ? column.content_alignment
                      : "justify",
                  }}
                  small={PageData.mobile_font_sizes.copy}
                  large={PageData.desktop_font_sizes.copy}
                >
                  {column.content}
                </MarkdownRenderer>
              </Box>
            ))}
          </Flex>
        </Container>
      </Box>

      <Box as="section" py={[15, null, 16]} backgroundColor="lightGray">
        <Container variant="containerLg">
          <Box sx={{ textAlign: "center" }}>
            <Heading
              as="h1"
              sx={{
                textAlign: "center",
                fontSize: [
                  PageData.mobile_font_sizes.title,
                  null,
                  null,
                  PageData.desktop_font_sizes.title,
                ],
              }}
            >
              {PageData.block_2_title}
            </Heading>

            <MarkdownRenderer
              sx={{
                textAlign: PageData?.block_2_alignment
                  ? PageData?.block_2_alignment
                  : "center",
                mt: 9,
                fontSize: "20px",
              }}
            >
              {PageData.block_2_intro}
            </MarkdownRenderer>
          </Box>

          <Flex
            mt={17}
            sx={{
              flexWrap: "wrap",
              justifyContent: ["center", null, null, "space-between"],
            }}
          >
            {PageData.block_2_columns.map((column, index) => (
              <Box
                sx={{
                  flex: ["0 0 100%", "0 0 80%", "0 0 55%", "0 0 32%"],
                  textAlign: "center",
                }}
                key={index}
              >
                <Box
                  sx={{
                    textAlign: ["center"],
                  }}
                >
                  <Image
                    src={column.image}
                    sx={{ maxWidth: "100%", height: "80px", width: "80px" }}
                  />
                </Box>

                <Heading
                  as="h2"
                  variant="headingSM"
                  sx={{
                    fontSize: [
                      PageData.mobile_font_sizes.sub_title,
                      null,
                      null,
                      PageData.desktop_font_sizes.sub_title,
                    ],
                  }}
                >
                  {column.title}
                </Heading>
                <MarkdownRenderer
                  sx={{
                    opacity: "0.8",
                    fontSize: [2, null, null, 2],
                    textAlign: column?.content_alignment
                      ? column?.content_alignment
                      : "center",
                  }}
                >
                  {column.content}
                </MarkdownRenderer>
              </Box>
            ))}
          </Flex>
        </Container>
      </Box>

      <Box
        as="section"
        backgroundColor="secondary"
        color="white"
        py={[13, null, 16]}
      >
        <Container>
          {PageData.block_3_rows.map((row, index) => (
            <Flex
              sx={{
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: ["center", "center", "center", "space-between"],
                flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                textAlign: index % 2 === 0 ? "left" : "right",
                ":nth-of-type(n+2)": {
                  mt: 16,
                },
              }}
              key={index}
            >
              <Box sx={{ flex: ["0 0 100%", null, "0 0 80%", "0 0 50%"] }}>
                <Heading
                  sx={{
                    fontSize: [
                      PageData.mobile_font_sizes.title,
                      null,
                      null,
                      PageData.desktop_font_sizes.title,
                    ],
                  }}
                >
                  {row.title}
                </Heading>

                <MarkdownRenderer
                  small={PageData.mobile_font_sizes.copy}
                  large={PageData.desktop_font_sizes.copy}
                  sx={{
                    textAlign: row?.content_alignment
                      ? row?.content_alignment
                      : "center",
                    mt: 2,
                  }}
                >
                  {row.content}
                </MarkdownRenderer>
              </Box>

              <Box
                mt={[13, null, null, 0]}
                sx={{
                  flex: ["0 0 100%", null, "0 0 80%", "0 0 45%"],
                  textAlign: ["center", null, null, "right"],
                }}
              >
                <Image
                  src={row.image}
                  sx={{ borderRadius: "20px", maxWidth: "100%" }}
                />
              </Box>
            </Flex>
          ))}

          <LinkButton
            buttonPos="center"
            buttonText="Learn More"
            linkTo="/platform"
            mt={[12, null, null, 16]}
            textTransform="uppercase"
          />
        </Container>
      </Box>

      <Box as="section" py={[15, null, 16]} backgroundColor="lightGray">
        <Container>
          <Flex sx={{ justifyContent: "center" }}>
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <Heading
                as="h1"
                mb={4}
                sx={{
                  textAlign: "center",
                  fontSize: [
                    PageData.mobile_font_sizes.title,
                    null,
                    null,
                    PageData.desktop_font_sizes.title,
                  ],
                }}
              >
                {PageData.block_4_title}
              </Heading>

              <MarkdownRenderer
                small={PageData.mobile_font_sizes.copy}
                large={PageData.desktop_font_sizes.copy}
                as="p"
                sx={{
                  textAlign: PageData?.block_4_alignment
                    ? PageData?.block_4_alignment
                    : "center",
                }}
              >
                {PageData.block_4_intro}
              </MarkdownRenderer>

              <Flex
                mt={[13, 17]}
                sx={{
                  flexWrap: "wrap",
                  flexDirection: ["column", "column", "row"],
                  width: "80%",
                  margin: "0 auto",
                  justifyContent: "center",
                  alignItems: ["center", "center", "flex-start"],
                }}
              >
                {PageData.block_4_columns.map((col, index) => (
                  <FramedImage
                    key={index}
                    altText={col.title}
                    captionText={col.title}
                    imageSrc={col.image}
                    small={PageData.mobile_font_sizes.copy}
                    large={PageData.desktop_font_sizes.copy}
                    mx={[0, null, 14]}
                    sx={{
                      px: 2,
                      mb: [6, null, null, 0],
                      mt: [6, null, null, 0],
                    }}
                  />
                ))}
              </Flex>

              <LinkButton
                buttonPos="center"
                buttonText="request a demo"
                linkTo="/request-a-demo"
                mt={17}
                textTransform="uppercase"
              />
            </Box>
          </Flex>
        </Container>
      </Box>

      <Container as="section" py={[15, null, 16]}>
        <Box mb={[14, null, null, 14]} sx={{ textAlign: "center" }}>
          <Heading as="h1">Recent Dosis News</Heading>
        </Box>

        <Grid
          sx={{
            gridTemplateColumns: [
              "repeat(1, 100%)",
              "repeat(1, 80%)",
              "repeat(2, 48%)",
              "repeat(3, 32%)",
            ],

            justifyContent: ["center", null, null, "space-between"],
            rowGap: [10, null, null, 13],
          }}
        >
          {blogArray.map((post, index) => (
            <BlogCard
              blogCategory={post.category}
              blogCategoryColor={"primary"}
              blogDate={post.date}
              blogExcerpt={post.excerpt}
              blogImageURL={post.image}
              blogLinkURL={post.link}
              imageFound={post.blogImageFound}
              blogTitle={post.title}
              key={index}
            />
          ))}
        </Grid>

        <ArrowLink
          linkPos="center"
          linkText="See all news &amp; insights"
          linkTo="/news"
          mt={14}
        />
      </Container>

      <Footer />
    </>
  );
};

export default Index;
