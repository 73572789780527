/** @jsx jsx */
import styled from "@emotion/styled";
import { Box, Flex, jsx } from "theme-ui";
import { Link } from "gatsby";

const StyledLink = styled(Link)`
  border-bottom: 2px solid transparent;
  color: #122629;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  white-space: nowrap;

  svg {
    display: block;
    margin-left: 8px;
    transition: all 0.2s linear;
  }

  &:hover {
    border-bottom: 2px solid #000;
  }

  @media (min-width: 768px) {
    svg {
      display: block;
      margin-left: 15px;
    }
  }
`;

const ArrowLink = ({ linkTo, mt, mb, linkPos, linkText, ...props }) => (
  <Box {...props} sx={{ textAlign: linkPos, mt, mb }}>
    <StyledLink to={linkTo} sx={{ fontSize: [3, null, null, 4] }}>
      <Flex as="span" sx={{ alignItems: "center" }}>
        <span>{linkText}</span>
        <span>
          <svg
            width="21"
            height="16"
            viewBox="0 0 21 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6826 6H0V10H12.6826V16L21 8L12.6826 0V6Z"
              fill="black"
            />
          </svg>
        </span>
      </Flex>
    </StyledLink>
  </Box>
);

export default ArrowLink;
